"use client";

import { FC } from "react";

// Next
import Link from "next/link";
import Image from "next/image";
import { useLocale } from "next-intl";

// Utils
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { IFQCN_BUI } from "@/utils/types/common";

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const Footer: FC<Props> = ({ fqcn_bui }) => {
  const locale = useLocale();

  return (
    <Link id={getFQCN(fqcn_bui, "link-logo")} href={`/${locale}`}>
      <div className="bg-white border border-gray-300 rounded pt-2 scale-80 shadow m-3 pb-2 w-32 dark:bg-dark-mode">
        <div className="flex place-content-center">
          <Image
            id={getFQCN(fqcn_bui, "light-logo")}
            src={"/image.webp"}
            width={80}
            height={30}
            alt="phpcreation logo"
            className="block dark:hidden"
          />

          <Image
            id={getFQCN(fqcn_bui, "dark-logo")}
            src={"/logo-darkmode.png"}
            width={80}
            height={30}
            alt="phpcreation logo"
            className="hidden dark:block"
          />
        </div>
      </div>
    </Link>
  );
};

export default Footer;
