import React, { useState } from "react";

// Next
import { usePathname } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";

// Components
import { Button } from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";

import Logo from "@/components/ui/Logo";

// Site data
import { SidebarMenus } from "@/sitedata/SidebarMenu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { HiOutlineBars3 } from "react-icons/hi2";
import { IoSettingsOutline } from "react-icons/io5";

const fqcnBUI = {
  Bundle: "sidebarMenuBundle",
  Unit: "menu",
  Interface: "Listing",
};

type SidebarMenuProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SidebarMenu: React.FC<SidebarMenuProps> = ({ isOpen, setIsOpen }) => {
  const locale = useLocale();
  const t = useTranslations("SidebarMenu");
  const pathname = usePathname();

  const [helpModal, setHelpModal] = useState(false);

  return (
    <>
      <Collapsible
        open={isOpen}
        onOpenChange={setIsOpen}
        className={`transition-transform duration-500 fixed md:relative h-full md:h-auto ${
          !isOpen
            ? "translate-x-[-100%] md:translate-x-0 shadow-xl"
            : "translate-x-0"
        }`}
      >
        <div className="bg-phpc-blue h-full dark:bg-phpc-blue-dark print:hidden">
          <article className="sticky top-0 p-2 flex flex-col h-screen">
            <div className="flex mb-3 justify-center">
              <CollapsibleContent></CollapsibleContent>
              <CollapsibleTrigger asChild>
                <div className="flex flex-row justify-center">
                  {isOpen && (
                    <Logo
                      fqcn_bui={{
                        Bundle: "homeBundle",
                        Unit: "logo",
                        Interface: "landing",
                      }}
                    />
                  )}
                  <Button
                    id={getFQCN(fqcnBUI, "menu", "collapsible-button")}
                    className="w-full m-auto mt-4 p-0 bg-transparent hover:bg-transparent"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <HiOutlineBars3
                      id={getFQCN(fqcnBUI, "menu", "collapsible-icon")}
                      className="w-7 h-7 text-white"
                    />
                  </Button>
                </div>
              </CollapsibleTrigger>
            </div>

            {SidebarMenus?.map((item, _id) => (
              <Link
                id={getFQCN(fqcnBUI, "menu", `${item?.title}-link`)}
                href={
                  !item?.externalLink ? `/${locale}${item?.link}` : item?.link
                }
                passHref={item?.externalLink}
                key={_id}
              >
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger
                      className={`flex w-full gap-4 p-3 rounded cursor-pointer transition-all ease-in-out duration-300 text-white ${
                        item?.link !== "/" && pathname.includes(item?.link)
                          ? "bg-phpc-blue-dark dark:bg-phpc-blue"
                          : " hover:text-phpc-blue-dark dark:hover:text-phpc-blue"
                      }`}
                    >
                      <span className="flex items-center justify-center">
                        {item?.icon}
                      </span>

                      <CollapsibleContent>
                        <p
                          className="w-full whitespace-nowrap overflow-hidden overflow-ellipsis"
                          id={getFQCN(fqcnBUI, "task-startDate")}
                        >
                          {t(item?.title)}
                        </p>
                      </CollapsibleContent>
                    </TooltipTrigger>

                    <TooltipContent>
                      <p>{t(item?.title)}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </Link>
            ))}

           {/*  <div className="mt-auto mx-auto mb-10">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger
                    className="flex items-center w-full gap-4 p-3 rounded cursor-pointer transition-all ease-in-out duration-300 text-white hover:text-phpc-blue-dark dark:hover:text-phpc-blue"
                    onClick={() => setHelpModal(true)}
                  >
                    <span className="flex items-center justify-center">
                      <IoSettingsOutline
                        className="h-5 w-5 mx-auto"
                        id={getFQCN(fqcnBUI, "menu", "help-icon")}
                      />
                    </span>
                    <CollapsibleContent>
                      <p
                        className="w-full whitespace-nowrap overflow-hidden overflow-ellipsis"
                        id={getFQCN(fqcnBUI, "task-startDate")}
                      >
                        {t("Shortcuts")}
                      </p>
                    </CollapsibleContent>
                  </TooltipTrigger>
                </Tooltip>
              </TooltipProvider>
            </div> */}
          </article>
        </div>
      </Collapsible>

      <div
        className={`fixed inset-0 z-[998] bg-black/50 transition-opacity duration-500 md:hidden ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setIsOpen(false)}
      ></div>

      {/* {helpModal && (
        <HelpModal isOpen={helpModal} onClose={() => setHelpModal(false)} />
      )} */}
    </>
  );
};

export default SidebarMenu;
